// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require select2

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("@popperjs/core")

import * as bootstrap from 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'
window.bootstrap = bootstrap;

require('jquery')
window.jQuery = $;
window.$ = $;

import 'select2'

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// The stylesheet location we created earlier
require("../stylesheets/application.scss")

Rails.start()
ActiveStorage.start()

$(function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
})

window.imageLoaded = (img) => {
  $(img).parents('.loading').removeClass('loading');
  $(img).parent().addClass('fade_in')
}

$(document).ready(function() {
  $('.select2').each(function () {
    $(this).select2({
      dropdownParent: $(this).parent().parent(),
      templateResult: formatState
    });
  });
})

window.formatState = (state) => {
  let optionEl = $(state.element);
  let customerData = optionEl.data('customer');
  if (customerData) {
    return $(`<div>${customerData.name} (Tier: ${customerData.tier})<br><small>${customerData.address}</small><div>`);
  }
  return state.text;
}


$(document).ready(function () {
  $('.modal').on('shown.bs.modal', function () {
    $(this).find('input:visible:first').focus();
  })
})
